import React, { useEffect } from 'react';
import PacksWidget from '../components/PacksWidget/PacksWidget'
import { Helmet } from "react-helmet-async";



export default function StarAtlasPacksWidget() {
  useEffect(() => {
    import('../components/PacksWidget/css/starAtlas.scss');
  }, []);
  return (
    <>
      <Helmet>
        <title>Tulle - Star Atlas Starter Packs</title>
      </Helmet>
      <PacksWidget partnerToken={process.env.REACT_APP_STAR_ATLAS_TOKEN!} loadDinamicCSS={false} maintenance={false} />
    </>
  );
}
