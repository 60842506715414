import { useState, useCallback, useMemo } from 'react'
import { PackGroup } from '../types/packs'
import { PacksClient } from '../clients/packsClient'

const usePackGroups = (partnerToken: string) => {
  const packsClient = useMemo(() => {
    return new PacksClient({ jwtToken: partnerToken })
  }, [partnerToken])

  const [packGroups, setPackGroups] = useState<PackGroup[]>([])
  const [isLoadingPackGroups, setIsLoadingPackGroups] = useState<boolean>(false)
  const [errorPackGroups, setErrorPackGroups] = useState<Error | null>(null)

  const fetchPackGroups = useCallback(async (): Promise<void> => {
    setIsLoadingPackGroups(true)
    setErrorPackGroups(null)
    try {
      console.log('fetching pack groups')
      const data = await packsClient.fetchPackGroups()
      setPackGroups(data)
      console.log('data', data)
    } catch (err) {
      setErrorPackGroups(err as Error)
    } finally {
      setIsLoadingPackGroups(false)
    }
  }, [partnerToken])

  return {
    packGroups,
    isLoadingPackGroups,
    errorPackGroups,
    fetchPackGroups,
  }
}

export default usePackGroups
